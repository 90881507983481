export const API_MATCH_FULL_BIRTHDAY_FORMAT = 'DD-MM-YYYY';
export const API_MATCH_PARTIAL_BIRTHDAY_FORMAT = 'DD-MM';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const API_DATE_TIME_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_TIME_FORMAT = 'HH:mm';
export const DISPLAY_TIME_FORMAT_12_HOURS = 'hh:mm A';

export enum DurationFormat {
  HoursMinutesSeconds = 'HH:mm:ss',
  HoursMinutes = 'HH:mm',
  Hours = 'HH',
  Minutes = 'mm',
}
