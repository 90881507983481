import { SEPInternalFrontendUrlKeyType } from 'src/declarations/config';
import { Storage } from 'src/services/storages/storage';
import { localStorageService } from 'src/services/storages/localStorage/localStorageService';

export const createLocalStorageForMicroservice = (
  microservice: SEPInternalFrontendUrlKeyType
): Storage => {
  const get = (key: string): string | null =>
    localStorageService.get(key, microservice);
  const getParsed = <T>(key: string): T | null =>
    localStorageService.getParsed(key, microservice);
  const set = (key: string, value: any) =>
    localStorageService.set(key, value, microservice);
  const setParsed = (key: string, value: string) =>
    localStorageService.setParsed(key, value, microservice);
  const remove = (key: string) => localStorageService.remove(key, microservice);
  const clear = () => localStorageService.clear(microservice);

  return {
    get,
    getParsed,
    set,
    setParsed,
    remove,
    clear,
  };
};
