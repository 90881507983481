import React from '../../../wc/index';

export interface IDataGridCheckboxCellRendererProps {
  checked: boolean;
  handleCheck: () => void;
  data?: any;
  isSelectionDisabled?: boolean;
}

export default function dataGridCheckboxCellRenderer(
  props: IDataGridCheckboxCellRendererProps
) {
  const { checked, handleCheck, data, isSelectionDisabled } = props;

  return (
    <ac-checkbox
      checked={checked}
      onChangeCallback={handleCheck}
      disabled={Boolean(isSelectionDisabled) || (data && data.disabled)}
    />
  );
}
