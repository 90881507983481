import { I18N_DEFAULT_NS, TRANSLATIONS_DIRECTORY } from './constants';

export const getNamespaceFromUrl = (url: string) => {
  const parts = url.split('/');
  const potentialNamespace = parts[parts.length - 2];
  if (potentialNamespace === TRANSLATIONS_DIRECTORY) {
    return I18N_DEFAULT_NS;
  }

  return potentialNamespace;
};
