import { createBrowserHistory, History } from 'history';

let historyInstance: History | undefined;

export const getHistoryInstance = (): History => {
  if (!historyInstance) {
    historyInstance = createBrowserHistory();
  }

  return historyInstance;
};

export const clearHistoryInstance = () => {
  historyInstance = undefined;
};
