import { createContext } from 'react';

import { SystemBusinessContext } from '@ac/library-api';

import { useDefinedContext } from '../../../store';

import { SystemWorkContextStore } from './store';

export const SystemWorkContext = createContext<
  SystemWorkContextStore | undefined
>(undefined);

export const useSystemWorkContext = (): SystemWorkContextStore =>
  useDefinedContext(SystemWorkContext);

export const useSystemDefinedBusinessContext = (): SystemBusinessContext => {
  const {
    state: { businessContext },
  } = useSystemWorkContext();

  if (!businessContext) {
    throw new Error(
      'The business context has not been loaded yet. Initialize it before usage.'
    );
  }

  return businessContext;
};
