/**
 * @class DataGridRow
 */

import * as React from 'react';

interface IDataGridRowProps<T = any> {
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  data?: T;
  onClick?: (data: {
    indexColumn: number;
    indexRow: number;
    data: T;
    id: string;
  }) => void;
  rowGroup?: boolean;
  class?: string;
}

export default class DataGridRow<T = any> extends React.Component<
  IDataGridRowProps<T>
> {
  public render() {
    return null;
  }
}
