export const isEmpty = (element: unknown): boolean => {
  if (typeof element === 'boolean') {
    return !element;
  }

  if (element === null || element === undefined || Number.isNaN(element)) {
    return true;
  }

  if (Array.isArray(element) || typeof element === 'string') {
    return element.length === 0;
  }

  if (
    Object.keys(element as object).length === 0 &&
    typeof element === 'object'
  ) {
    return true;
  }

  return false;
};
