/**
 * @class DataGridSecondaryCells
 */

import { Component } from 'react';

import { PropsWithChildren } from '../../../features/reactTransition/propsWithChildren';

export default class DataGridSecondaryCells extends Component<PropsWithChildren> {
  public render() {
    return null;
  }
}
