import { SEPInternalFrontendUrlKeyType } from 'src/declarations/config';
import { Storage } from 'src/services/storages/storage';
import { sessionStorageService } from 'src/services/storages/sessionStorage/sessionStorageService';

export const createSessionStorageForMicroservice = (
  microservice: SEPInternalFrontendUrlKeyType
): Storage => {
  const get = (key: string): string | null =>
    sessionStorageService.get(key, microservice);
  const getParsed = <T>(key: string): T | null =>
    sessionStorageService.getParsed(key, microservice);
  const set = (key: string, value: any) =>
    sessionStorageService.set(key, value, microservice);
  const setParsed = (key: string, value: string) =>
    sessionStorageService.setParsed(key, value, microservice);
  const remove = (key: string) =>
    sessionStorageService.remove(key, microservice);
  const clear = () => sessionStorageService.clear(microservice);

  return {
    get,
    getParsed,
    set,
    setParsed,
    remove,
    clear,
  };
};
