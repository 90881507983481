import { InitOptions } from 'i18next';
import { SessionService } from '../services';

export const getI18nextInitOptions = (
  optionsToOverride: InitOptions
): InitOptions => {
  const defaultLng = 'en';
  const lng = SessionService.getLanguage() || defaultLng;

  return {
    fallbackLng: defaultLng,
    lng,
    debug: true,
    lowerCaseLng: true,
    ns: ['translations'],
    defaultNS: 'translations',
    ...optionsToOverride,
  };
};
