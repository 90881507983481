import { createContext } from 'react';

import { CentralReservationOfficeBusinessContext } from '@ac/library-api';

import { useDefinedContext } from '../../../../store';

import { CentralReservationOfficeWorkContextStore } from './store';

export const CentralReservationOfficeWorkContext = createContext<
  CentralReservationOfficeWorkContextStore | undefined
>(undefined);

export const useCentralReservationOfficeWorkContext =
  (): CentralReservationOfficeWorkContextStore =>
    useDefinedContext(CentralReservationOfficeWorkContext);

export const useCentralReservationOfficeDefinedBusinessContext =
  (): CentralReservationOfficeBusinessContext => {
    const {
      state: { businessContext },
    } = useCentralReservationOfficeWorkContext();

    if (!businessContext) {
      throw new Error(
        'The business context has not been loaded yet. Initialize it before usage.'
      );
    }

    return businessContext;
  };
