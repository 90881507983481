/**
 * @class DataGrid
 */

import { Children, PureComponent, ReactNode } from 'react';

import React from '../../../wc/index';
import { LayoutHelpers } from '../helpers';

import DataGridAdditionalRow from './DataGridAdditionalRow';
import DataGridCell from './DataGridCell';
import DataGridCellInternal from './DataGridCellInternal';
import dataGridCheckboxCellRenderer, {
  IDataGridCheckboxCellRendererProps,
} from './dataGridCheckboxCellRenderer';
import DataGridHeading from './DataGridHeading';
import DataGridRow from './DataGridRow';
import DataGridSecondaryCell from './DataGridSecondaryCell';
import DataGridSecondaryCells from './DataGridSecondaryCells';

import './DataGrid.scss';

interface IDataGridProps {
  class?: string;
  id?: string;
  checkedItems?: string[] | number[];
  checkKey?: string;
  checkBoxColumn?: boolean;
  onCheckItem?: (selectedItems: string[] | number[]) => void;
  checkboxCellRenderer?: (
    props: IDataGridCheckboxCellRendererProps
  ) => React.ReactNode;
  testSelector?: string;
  highlightAdditionalRow?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  newCssRowControl?: boolean;
  headerCheckboxTooltip?: string;
  isSelectionDisabled?: boolean;
}

interface IComposedGridCell {
  cell: React.ReactNode[] | React.ReactNode;
  rowIndex?: number;
  disabled?: boolean;
  columns?: number;
  columnIndex?: number;
}

export default class DataGrid extends PureComponent<IDataGridProps> {
  public static defaultProps: IDataGridProps = {
    checkedItems: [],
    checkKey: 'id',
    highlightAdditionalRow: true,
    disabled: false,
  };

  private readonly id: string;
  private dataSet: any[] = [];

  constructor(props: IDataGridProps) {
    super(props);

    this.id = this.props.id || LayoutHelpers.getRandomString();
  }

  public render() {
    this.dataSet = [];
    let additionalCells = 0;
    let checkCell: any = null;
    let headerExtraCell: ReactNode | null = null;
    let body: ReactNode[] = [];
    if (this.props.checkBoxColumn && this.props.onCheckItem) {
      additionalCells = 1;
      checkCell = this.checkboxCell;
      headerExtraCell = this.headerCheckbox as unknown as ReactNode;
    }

    const gridHeading: ReactNode = LayoutHelpers.findChild(
      DataGridHeading,
      this.props.children
    );
    const gridRows: DataGridRow[] = LayoutHelpers.findChildren(
      DataGridRow,
      this.props.children
    );

    const config = (gridHeading as any)?.props.configuration ?? [];

    const style = {
      gridTemplateColumns: `${additionalCells > 0 ? '50px ' : ''}${config
        .map((e: any) => e.size)
        .join(' ')}`,
    };

    const addRowData = (props: any, data: any) => {
      if (!props.disabled) {
        this.dataSet.push({ ...data });
      }
    };

    if (this.props.newCssRowControl) {
      const renderRow = (
        row: DataGridRow,
        rowIndex: number,
        renderCheckbox: boolean,
        noBorder: boolean
      ) => {
        const columnsAmount =
          config.filter((i: any) => i.size).length + additionalCells;
        const cells: ReactNode[] = [
          ...(additionalCells > 0 && renderCheckbox
            ? [
                <DataGridCell
                  key={0}
                  class={noBorder ? 'ac-datagrid-no-border' : ''}
                >
                  {this.checkboxCell(row.props.data)}
                </DataGridCell>,
              ]
            : []),
          ...Children.toArray(row.props.children),
        ];

        return (cells as unknown as DataGridCell[]).map((cell, index) => {
          let span = cell.props.span || 0;

          if (cell.props.spanAll) {
            span = columnsAmount - index;
          }

          return (
            <DataGridCellInternal
              {...cell.props}
              key={index}
              indexRow={rowIndex}
              indexColumn={index}
              columnsAmount={columnsAmount}
              span={span}
              newCssRowControl={true}
            >
              {cell.props.children}
            </DataGridCellInternal>
          );
        });
      };

      gridRows.forEach((row, rowIndex) => {
        let rowContent;
        if (row.props.rowGroup) {
          const subRows = Children.toArray(row.props.children);
          rowContent = subRows.map((subRow: any, subRowIndex) => {
            const subRowContent = (
              <div
                key={subRowIndex}
                className={`ac-datagrid-grid-sub-row${
                  subRow.props.class ? ` ${subRow.props.class}` : ''
                }`}
              >
                {renderRow(
                  subRow,
                  rowIndex,
                  subRowIndex === 0,
                  subRows.length !== subRowIndex + 1
                )}
              </div>
            );

            return subRowContent;
          });
        } else {
          rowContent = renderRow(row, rowIndex, true, false);
        }

        addRowData(row.props, row.props.data);

        body.push(
          <div
            key={rowIndex}
            className={`ac-datagrid-grid-row row-${rowIndex}${
              row.props.class ? ` ${row.props.class}` : ''
            }`}
          >
            {rowContent}
          </div>
        );
      });
    } else {
      const cells: IComposedGridCell[] = [];
      gridRows.map((row: DataGridRow, rowIndex) => {
        const additionalRow: DataGridAdditionalRow = LayoutHelpers.findChild(
          DataGridAdditionalRow,
          row.props.children
        );

        const dataGridCells: DataGridCell[] = LayoutHelpers.findChildren(
          DataGridCell,
          row.props.children
        );
        const dataGridSecondaryCellGroups: DataGridSecondaryCells[] =
          LayoutHelpers.findChildren(
            DataGridSecondaryCells,
            row.props.children
          );

        const dataGridSecondaryCells = dataGridSecondaryCellGroups.reduce(
          (groups: DataGridSecondaryCells[], group: DataGridSecondaryCells) => {
            const secondaryCells: DataGridSecondaryCells[] =
              LayoutHelpers.findChildren(
                DataGridSecondaryCell,
                group.props.children
              );
            groups.push(...secondaryCells);

            return groups;
          },
          []
        );

        const rowData =
          row.props.data || (dataGridCells as DataGridCell[])?.[0]?.props?.data;

        const cellsCount = dataGridCells.reduce<number>(
          (sum, cell: DataGridCell) => (cell.props.span || 1) + sum,
          0
        );

        addRowData(row.props, rowData);

        if (additionalCells) {
          cells.push({
            cell: checkCell({ ...rowData, disabled: row.props.disabled }),
            rowIndex,
            disabled: row.props.disabled,
            columnIndex: 0,
            columns: cellsCount + additionalCells,
          });
        }

        const gridCells: IComposedGridCell[] = dataGridCells.map(
          (cell: DataGridCell, index: number) => {
            const data = cell.props.data || row.props.data;
            const columnIndex = index + additionalCells;

            return {
              cell: {
                ...cell,
                disabled: row.props.disabled,
              } as unknown as React.ReactNode,
              rowIndex,
              disabled: row.props.disabled,
              columnIndex,
              columns: dataGridCells.length + additionalCells,
              data,
              key: data?.id ? `${data.id}-${columnIndex}` : undefined,
              onClick: cell.props.onClick || row.props.onClick,
            };
          }
        );

        if (Array.isArray(dataGridCells) && dataGridCells.length) {
          cells.push(...gridCells);
        }

        const gridSecondaryCells = dataGridSecondaryCells.map(
          (cell: DataGridSecondaryCell, index: number) => {
            const data = cell.props.data || row.props.data;
            const columnIndex = index + additionalCells;

            return {
              cell: { ...cell, disabled: row.props.disabled },
              rowIndex,
              disabled: row.props.disabled,
              columnIndex: index,
              columns: dataGridCells.length + additionalCells,
              data,
              key: data?.id ? `${data.id}-secondary-${columnIndex}` : undefined,
              onClick: cell.props.onClick || row.props.onClick,
              isSecondary: true,
            };
          }
        );

        if (Array.isArray(gridSecondaryCells) && gridSecondaryCells.length) {
          cells.push(...(gridSecondaryCells as unknown as IComposedGridCell[]));
        }

        if (additionalRow) {
          cells.push({
            cell: additionalRow as unknown as ReactNode,
            disabled: row.props.disabled,
            rowIndex,
            columns: cellsCount + additionalCells,
          });
        }
      });

      body = cells.map((composedCell: any) => {
        const cellName = `cell${composedCell.isSecondary ? '-secondary' : ''}`;
        const columnIndex = `${
          composedCell.columnIndex === undefined
            ? 'additional-row'
            : composedCell.columnIndex
        }`;
        const key =
          composedCell.key ??
          `${cellName}-${composedCell.rowIndex}-${columnIndex}`;

        const props = {
          disabled: composedCell.disabled,
          indexRow: composedCell.rowIndex,
          indexColumn: composedCell.columnIndex,
          columnsAmount: composedCell.columns,
          ...composedCell.cell.props,
          data: composedCell.cell.props.data || composedCell.data,
          onClick: composedCell.cell.props.onClick || composedCell.onClick,
          highlightAdditionalRow: this.props.highlightAdditionalRow,
          key,
          gridId: this.id,
          class: `${composedCell.cell.props.class || ''} row-${
            composedCell.rowIndex
          }`,
          isSecondary: composedCell.isSecondary,
          testSelector: composedCell.cell.props.testSelector || key,
        };

        return composedCell.cell.type === DataGridAdditionalRow ? (
          <DataGridAdditionalRow {...props}>
            {props.children}
          </DataGridAdditionalRow>
        ) : (
          <DataGridCellInternal {...props}>
            {props.children}
          </DataGridCellInternal>
        );
      });
    }

    return (
      <div
        className={this.getClassList()}
        id={this.id}
        style={style}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
      >
        <DataGridHeading
          {...(gridHeading as unknown as DataGridHeading).props}
          additionalCell={headerExtraCell}
        >
          {(gridHeading as unknown as DataGridHeading).props.children}
        </DataGridHeading>
        {body}
      </div>
    );
  }

  private checkboxCell = (data?: any) => {
    const { checkKey, checkedItems, onCheckItem, isSelectionDisabled } =
      this.props;
    if (!onCheckItem) {
      return null;
    }
    const itemKey = LayoutHelpers.getValueByPath(data, checkKey!);
    const checked = checkedItems
      ? (checkedItems as any[]).includes(itemKey)
      : false;
    const handleCheck = () => {
      if (checked) {
        onCheckItem(
          (checkedItems as string[]).filter((item) => item !== itemKey)
        );
      } else {
        onCheckItem([...(checkedItems as string[]), itemKey]);
      }
    };

    return (
      <div>
        {this.props.checkboxCellRenderer
          ? this.props.checkboxCellRenderer({
              checked,
              handleCheck,
              data,
              isSelectionDisabled,
            })
          : dataGridCheckboxCellRenderer({
              checked,
              handleCheck,
              data,
              isSelectionDisabled,
            })}
      </div>
    );
  };

  private headerCheckbox = () => {
    const {
      checkedItems,
      onCheckItem,
      isSelectionDisabled,
      headerCheckboxTooltip,
    } = this.props;
    if (!onCheckItem) {
      return null;
    }
    const available = this.getAvailableOptions();
    const checkedItemsLength = checkedItems?.length ?? 0;
    const checked = checkedItemsLength === available.length;
    const onChange = () => {
      onCheckItem(checked ? [] : [...available]);
    };

    return (
      <div id={`datagrid-header-checkbox-${this.props.id}`}>
        <ac-checkbox
          checked={checked}
          indeterminate={
            checkedItemsLength > 0 && checkedItemsLength < available.length
          }
          onChangeCallback={onChange}
          disabled={Boolean(isSelectionDisabled)}
        />
        {headerCheckboxTooltip && (
          <ac-tooltip
            for={`#datagrid-header-checkbox-${this.props.id}`}
            text={headerCheckboxTooltip}
            closeOnTargetClick={true}
          />
        )}
      </div>
    );
  };

  private getAvailableOptions = () => {
    return this.dataSet.map((entry: any) => {
      return LayoutHelpers.getValueByPath(entry, this.props.checkKey!);
    });
  };

  private getClassList(): string {
    const classList: string[] = [];

    classList.push('ac-datagrid');

    if (this.props.class) {
      classList.push(this.props.class);
    }

    return classList.join(' ');
  }
}
