import { RenderableProps } from './field/types';

export function renderElement<ElementProps>(
  propsWithElement: RenderableProps<ElementProps>,
  props: ElementProps
) {
  const Element = propsWithElement.children;

  if (Element instanceof Function) {
    return Element(props);
  }

  return Element;
}
