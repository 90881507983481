function hexToRgb(hex: string): any {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(
    shorthandRegex,
    function (_m: string, r: number, g: number, b: number) {
      return (r + r + g + g + b + b).toString();
    }
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
}

export const isDarkBackground = (hex: string): boolean => {
  const color = hexToRgb(String(hex));

  if (!color) {
    return false;
  }
  // Counting the perceived luminance - human eye favors green color
  const luminance =
    (0.299 * color.red + 0.587 * color.green + 0.114 * color.blue) / 255;

  return luminance < 0.5;
};
