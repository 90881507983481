import { getGlobalRegionCode } from './getGlobalRegionCode';

export const getCurrentRegionCode = () => {
  const index = window.location.hostname.indexOf('.web');
  if (index > 0) {
    return window.location.hostname.substring(0, index).toLowerCase();
  }

  return getGlobalRegionCode();
};
