import { DateUnit } from '../types';
import { DurationFormat } from '../formats';
import moment from 'moment';

export interface DurationPartials {
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * @deprecated Use `DurationDateUtils` instead (moment.js is not supported anymore)
 */
export class DurationDateUtilsDeprecated {
  public static partials(time: number, unit: DateUnit): DurationPartials {
    const duration = moment.duration(Math.abs(time), unit);
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;

    return {
      hours,
      minutes,
      seconds:
        Math.floor(duration.asSeconds()) - minutes * 60 - hours * 60 * 60,
    };
  }

  public static format(
    partials: DurationPartials,
    format: DurationFormat
  ): string {
    const hours = partials.hours.toString().padStart(2, '0');
    const minutes = partials.minutes.toString().padStart(2, '0');
    const seconds = partials.seconds.toString().padStart(2, '0');

    const formats = {
      [DurationFormat.HoursMinutesSeconds]: `${hours}:${minutes}:${seconds}`,
      [DurationFormat.HoursMinutes]: `${hours}:${minutes}`,
      [DurationFormat.Hours]: hours,
      [DurationFormat.Minutes]: minutes,
    };

    if (!formats[format]) {
      throw new Error('Unsupported duration format provided!');
    }

    return formats[format];
  }
}
