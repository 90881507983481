import { IBreadcrumbHistoryItem } from '../../../components/layout/Breadcrumbs/historyItem';

import { CurrentRouteData } from './currentRouteData';

export type OnNavigateCallback = {
  (path: string): void;
};

export type OnNavigateBackCallback = {
  (): void;
};

export interface RouteChangeCallbacksNavigate {
  onNavigate?: OnNavigateCallback;
  onNavigateBack?: OnNavigateBackCallback;
}

export interface RouteChangeCallbacks extends RouteChangeCallbacksNavigate {
  onAuthenticateRouteChange?: OnNavigateCallback;
}

export type RouteChangeCallbacksRequired = Required<RouteChangeCallbacks>;

export type OnBeforePageUnloadCallback = { (pathname?: string): void };

export interface RoutingState {
  currentRoute: CurrentRouteData;
  history: Array<Readonly<IBreadcrumbHistoryItem>>;
  routeChangeCallbacks: RouteChangeCallbacksRequired;
  onBeforePageUnload?: OnBeforePageUnloadCallback;
}

export const defaultNavigateCallback: OnNavigateCallback = (path) => {
  try {
    window?.history?.pushState({}, '', path);
  } catch (error) {
    if (window?.location?.href) {
      window.location.href = path;
    }
  }
};

export const defaultNavigateBackCallback: OnNavigateBackCallback = () => {
  window?.history?.go(-1);
};

export const defaultRouteChangeCallbacks: RouteChangeCallbacksRequired =
  Object.freeze({
    onAuthenticateRouteChange: defaultNavigateCallback,
    onNavigate: defaultNavigateCallback,
    onNavigateBack: defaultNavigateBackCallback,
  });

export const getDefaultRoutingState = (
  routeChangeCallbacks?: RouteChangeCallbacks
): RoutingState => ({
  currentRoute: {
    title: '',
  },
  history: [],
  routeChangeCallbacks: {
    ...defaultRouteChangeCallbacks,
    ...(routeChangeCallbacks || {}),
  },
});
