/**
 * @class DataGridAdditionalRow
 */

import * as React from 'react';

import { LayoutHelpers } from '../helpers';

interface IDataGridAdditionalRowProps {
  columnsAmount?: number;
  indexRow?: number;
  class?: string;
  gridId?: string;
  onClick?: (data: { indexRow: number }) => void;
  highlightAdditionalRow?: boolean;
  testSelector?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export default class DataGridAdditionalRow extends React.Component<IDataGridAdditionalRowProps> {
  constructor(props: IDataGridAdditionalRowProps) {
    super(props);
  }

  public render() {
    const computedColumns = {
      gridColumnEnd: `${this.props.columnsAmount! + 1}`,
    };

    return (
      <div
        style={computedColumns}
        className={`${this.getClassList()}`}
        onClick={this.onClick}
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
      >
        {this.props.children}
      </div>
    );
  }

  private onMouseEnter = () => this.highlightingRow('add');
  private onMouseLeave = () => this.highlightingRow('remove');

  private onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (this.props.onClick) {
      this.props.onClick({ indexRow: this.props.indexRow! });
      e.stopPropagation();
    }
  };

  private highlightingRow = (action: 'add' | 'remove') => {
    const { indexRow, columnsAmount, highlightAdditionalRow } = this.props;
    const gridSelector = this.props.gridId ? `#${this.props.gridId} >` : '';

    if (!highlightAdditionalRow) {
      return;
    }

    const first = indexRow! * columnsAmount!;
    const array = document.querySelectorAll(
      `${gridSelector} .ac-datagrid-cell`
    );
    const additionalRow = document.querySelector(
      `${gridSelector} .ac-datagrid-additional-row.row-${indexRow}`
    );

    if (action === 'add') {
      for (let i = 0; i < columnsAmount!; i++) {
        array[first + i].classList.add('ac-datagrid-selected-row');
      }
      if (additionalRow) {
        additionalRow.classList.add('ac-datagrid-additional-selected-row');
      }
    } else {
      for (let i = 0; i < columnsAmount!; i++) {
        array[first + i].classList.remove('ac-datagrid-selected-row');
      }
      if (additionalRow) {
        additionalRow.classList.remove('ac-datagrid-additional-selected-row');
      }
    }
  };

  private getClassList(): string {
    const classList: string[] = [];

    classList.push('ac-datagrid-additional-row');

    if (LayoutHelpers.isOdd(this.props.indexRow!)) {
      classList.push('ac-datagrid-additional-even-row');
    }

    if (this.props.class) {
      classList.push(this.props.class);
    }

    if (this.props.disabled) {
      classList.push('ac-datagrid-cell-disabled');
    }

    return classList.join(' ');
  }
}
