import { createContext } from 'react';

import { useDefinedContext } from '../../../../../store';
import { SinglePropertyWorkContextStore } from '../store';

export const SinglePropertyWorkContext = createContext<
  SinglePropertyWorkContextStore | undefined
>(undefined);

export const useSinglePropertyWorkContext =
  (): SinglePropertyWorkContextStore =>
    useDefinedContext(SinglePropertyWorkContext);
