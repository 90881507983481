/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyTypeInObjectOrArray, ValuePathType } from '../types';

export const generateFieldValuePath =
  <
    MainPathValueKey1 extends KeyTypeInObjectOrArray<any>,
    MainPathValueKey2 extends KeyTypeInObjectOrArray<any>,
    MainPathValueKey3 extends KeyTypeInObjectOrArray<any> = never,
    MainPathValueKey4 extends KeyTypeInObjectOrArray<any> = never
  >(
    mainPath: ValuePathType<
      MainPathValueKey1,
      MainPathValueKey2,
      MainPathValueKey3,
      MainPathValueKey4
    >
  ) =>
  <
    NestedPathValueKey1 extends KeyTypeInObjectOrArray<any>,
    NestedPathValueKey2 extends KeyTypeInObjectOrArray<any> = never,
    NestedPathValueKey3 extends KeyTypeInObjectOrArray<any> = never
  >(
    nestedPath: ValuePathType<
      NestedPathValueKey1,
      [MainPathValueKey4] extends [never] ? NestedPathValueKey2 : never,
      [MainPathValueKey3] extends [never] ? NestedPathValueKey3 : never
    >
  ): [MainPathValueKey3] extends [never]
    ? ValuePathType<
        MainPathValueKey1,
        MainPathValueKey2,
        NestedPathValueKey1,
        NestedPathValueKey2,
        NestedPathValueKey3
      >
    : [MainPathValueKey4] extends [never]
    ? ValuePathType<
        MainPathValueKey1,
        MainPathValueKey2,
        MainPathValueKey3,
        NestedPathValueKey1,
        NestedPathValueKey2
      >
    : ValuePathType<
        MainPathValueKey1,
        MainPathValueKey2,
        MainPathValueKey3,
        MainPathValueKey4,
        NestedPathValueKey1
      > => {
    return [...mainPath, ...nestedPath] as any;
  };
