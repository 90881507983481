interface Options {
  intervalTime: number;
  repeatCount: number;
  catchException: boolean;
}

const defaultOptions: Options = {
  intervalTime: 500,
  repeatCount: 20,
  catchException: false,
};

export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options?: Partial<Options> & { catchException?: false }
): Promise<T>;
export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options?: Partial<Options> & { catchException: true }
): Promise<T | undefined>;
export async function repeatableCall<T>(
  call: () => Promise<T>,
  checkResult: (result: T) => boolean,
  options: Partial<Options> = {}
): Promise<T | undefined> {
  const settings = {
    ...defaultOptions,
    ...options,
  };

  let wasException: boolean = false;

  const getResult = async () => {
    wasException = false;
    try {
      return await call();
    } catch (e) {
      wasException = true;

      if (settings.catchException) {
        return;
      }

      throw e;
    }
  };

  let result = await getResult();

  for (let i = 1; i < settings.repeatCount; i++) {
    if (!wasException && checkResult(result!)) {
      return result;
    }

    await new Promise((resolve) => setTimeout(resolve, settings.intervalTime));
    result = await getResult();
  }

  return result;
}
