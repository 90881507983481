/**
 * @class DataGridCell
 */

import * as React from 'react';

import { CellJustify } from './cellJustify';

export interface IDataGridCellProps<T = any> {
  content?: (i?: T) => React.ReactNode;
  data?: T;
  span?: number;
  spanAll?: boolean;
  sortField?: string;
  class?: string;
  id?: string;
  justify?: CellJustify;
  onClick?: (data: {
    indexColumn?: number;
    indexRow?: number;
    data?: T;
    id?: string;
  }) => void;
  onDoubleClick?: (data: {
    indexColumn?: number;
    indexRow?: number;
    data?: T;
    id?: string;
  }) => void;
  testSelector?: string;
  children?: React.ReactNode;
}

export default class DataGridCell<T = any> extends React.Component<
  IDataGridCellProps<T>
> {}
