/**
 * @class DataTableHeadCell
 */

import * as React from 'react';

import { IconName, Size, TextSize, TextWeight } from '@ac/web-components';

import { CellJustify } from '../DataGrid/cellJustify';
import { IDataGridHeaderProps } from '../DataGrid/DataGridHeader';
import Flex from '../Flex/Flex';
import { LayoutHelpers } from '../helpers';

interface IDataTableHeadCellProps {
  text?: string;
  iconName?: string | IconName;
  rowSpan?: 'top' | 'bottom';
  colSpan?: number;
  justify?: CellJustify;
  columnsAmount?: number;
  indexColumn?: number;
  onSortBy?: (value: string) => void;
  sortValue?: string;
  testSelector?: string;
  component?: (props?: any) => React.ReactNode;
  class?: string;
  size?: string;
  id?: string;
  _injected: any;
  sortField?: string;
}

export default class DataTableHeadCell extends React.PureComponent<IDataTableHeadCellProps> {
  public static defaultProps: IDataGridHeaderProps = {
    sortValue: '',
    justify: 'left',
    size: 'auto',
  };

  public render() {
    const currentSortArrowIcon = this.isDescendingSortValue()
      ? IconName.openDown
      : IconName.closeUp;
    const sortIconClass = this.isCurrentlySorted()
      ? 'currently-sorted'
      : 'sortable-column';
    const { component, text, iconName, sortField, justify, _injected } =
      this.props;

    return (
      <div
        id={this.props.id}
        onClick={this.sortBy}
        className={`${this.getClassList()}`}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
      >
        <Flex>
          {sortField && justify === 'right' ? (
            <ac-icon
              icon={currentSortArrowIcon}
              dynamicClass={`ac-spacing-right-sm ${sortIconClass}`}
            />
          ) : null}

          {component ? (
            component({ _injected })
          ) : text ? (
            <ac-text
              class="header-text"
              size={TextSize.sm}
              weight={TextWeight.semibold}
            >
              {text}
            </ac-text>
          ) : !text && iconName ? (
            <ac-icon icon={iconName as IconName | undefined} size={Size.lg} />
          ) : null}

          {sortField && justify !== 'right' ? (
            <ac-icon
              icon={currentSortArrowIcon}
              dynamicClass={`ac-spacing-left-sm ${sortIconClass}`}
            />
          ) : null}
        </Flex>
      </div>
    );
  }

  private isCurrentlySorted = () =>
    this.props.sortField === this.props.sortValue ||
    this.isDescendingSortValue();

  private isDescendingSortValue = () => {
    const sortField = this.props.sortField + '';
    const descendingSortValue =
      sortField.indexOf(',') !== -1
        ? sortField
            .split(',')
            .map((e) => `-${e}`)
            .toString()
        : `-${sortField}`;

    return descendingSortValue === this.props.sortValue;
  };

  private sortBy = () => {
    const sortField = this.props.sortField;
    if (this.props.onSortBy && sortField) {
      this.props.onSortBy(sortField);
    }
  };

  private getClassList(): string {
    const classList: string[] = [];

    const indexColumn = this.props.indexColumn;
    const columnsAmount = this.props.columnsAmount as number;
    const rowSpan = this.props.rowSpan;
    const colSpan = this.props.colSpan;

    classList.push('ac-datagrid-header');
    if (this.props.justify) {
      classList.push(`ac-datagrid-header-justify-${this.props.justify}`);
    }

    if (rowSpan) {
      classList.push(`ac-datagrid-header-rowspan-${rowSpan}`);
    }

    if (colSpan) {
      classList.push(`ac-datagrid-header-colspan-${colSpan}`);
    }

    if (indexColumn === 0) {
      classList.push('ac-datagrid-header-first-cell');
    }

    if (
      indexColumn === columnsAmount - 1 ||
      (indexColumn === columnsAmount - 2 && rowSpan === 'top')
    ) {
      classList.push('ac-datagrid-header-last-cell');
    }

    // This one is for cases when in last column we have two header cells (top & bottom)
    if (this.props.sortField) {
      classList.push('clickable-heading');
    }

    if (this.props.class) {
      classList.push(this.props.class);
    }

    return classList.join(' ');
  }
}
