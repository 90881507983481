import { createContext } from 'react';

import { TenantBusinessContext } from '@ac/library-api';

import { useDefinedContext } from '../../../../store';

import { TenantWorkContextStore } from './store';

export const TenantWorkContext = createContext<
  TenantWorkContextStore | undefined
>(undefined);

export const useTenantWorkContext = (): TenantWorkContextStore =>
  useDefinedContext(TenantWorkContext);

export const useTenantDefinedBusinessContext = (): TenantBusinessContext => {
  const {
    state: { businessContext },
  } = useTenantWorkContext();

  if (!businessContext) {
    throw new Error(
      'The business context has not been loaded yet. Initialize it before usage.'
    );
  }

  return businessContext;
};
