interface Window {
  ACP?: ACPNamespace;
}

interface ACPNamespace {
  [key: string]: unknown;
}

export const getGlobalACPNamespace = () => {
  const global = window as Window;
  const acp = (global.ACP = global.ACP || {});

  return acp;
};
