const regexSpecialCharacters: string[] = [
  '.',
  '*',
  '-',
  '+',
  '?',
  '^',
  '$',
  '{',
  '}',
  '(',
  ')',
  '|',
  '\\',
  '[',
  ']',
];

export const additionalWordSeparators: string[] = [
  '.',
  ',',
  '-',
  '+',
  '(',
  ')',
  '[',
  ']',
  '/',
  '#',
];

export const isRegexSpecialCharacter = (char: string): boolean => {
  return !!regexSpecialCharacters.find((specialChar) => specialChar === char);
};

export const getEscapedRegexSpecialCharacter = (char: string): string => {
  return isRegexSpecialCharacter(char) ? `\\${char}` : char;
};

export const getEscapedRegexString = (word: string): string => {
  return word
    .split('')
    .map((char) =>
      isRegexSpecialCharacter(char)
        ? getEscapedRegexSpecialCharacter(char)
        : char
    )
    .join('');
};

export const getWordSeparatorsForMatcher = () => {
  return `[\\s${getEscapedRegexString(additionalWordSeparators.join(''))}]`;
};
