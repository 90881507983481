import { ArrayElement } from '@ac/library-utils/dist/declarations';
import { ErrorObject, ErrorType, ValidationStatus } from '@ac/web-components';

export const FORM_ERROR = 'FINAL_FORM/form-error';

export type DefaultErrorType = ValidationStatus;
export { ValidationStatus as ValidationResult };
export { ErrorObject as ValidationObject };
export { ErrorType as ValidationSeverity };

export type FormValidationErrors<Values> = ValidationErrors<Values>;

export type ValidationErrors<
  Values,
  AreArrayValidationFieldsRequired extends boolean = false
> = CommonValidationErrors<
  NestedValidationErrors<Values, AreArrayValidationFieldsRequired>
>;

export type InternalValidationErrors<Values> = CommonValidationErrors<
  InternalNestedValidationErrors<Values>
>;

type CommonValidationErrors<NestedValidation> = {
  [FORM_ERROR]?: DefaultErrorType;
} & NestedValidation;

export type NestedValidationErrors<
  Values,
  AreArrayValidationFieldsRequired extends boolean = false
> = CommonNestedValidationErrors<
  Values,
  {
    [Key in keyof Values]?: ConfigurableValueNestedValidationErrors<
      Values[Key],
      AreArrayValidationFieldsRequired extends false
        ? ArrayValidationErrors<ArrayElement<Values[Key]>>
        : Required<ArrayValidationErrors<ArrayElement<Values[Key]>, true>>,
      NestedValidationErrors<Values[Key], AreArrayValidationFieldsRequired>
    >;
  }
>;

export type InternalNestedValidationErrors<Values> =
  CommonNestedValidationErrors<
    Values,
    {
      [Key in keyof Values]?: ConfigurableValueNestedValidationErrors<
        Values[Key],
        InternalArrayError<ArrayElement<Values[Key]>>,
        InternalNestedValidationErrors<Values[Key]>
      >;
    }
  >;

type CommonNestedValidationErrors<Values, NestedValidation> =
  Values extends object ? NestedValidation : DefaultErrorType;

type ConfigurableValueNestedValidationErrors<
  ValueType,
  ArrayValueErrorType,
  RegularValueErrorType
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
> = ValueType extends any[]
  ? ArrayValueErrorType
  : ValueType extends object
  ? RegularValueErrorType
  : DefaultErrorType;

export interface InternalArrayError<ElementType>
  extends Array<ArrayValueError<ElementType>> {
  arrayErrors: DefaultErrorType;
}

export type ArrayFieldValidationErrors<ElementType> =
  ArrayValidationErrors<ElementType>;

export interface ArrayValidationErrors<
  ElementType,
  AreArrayValidationFieldsRequired extends boolean = false
> {
  valuesErrors?: ArrayValuesErrors<
    ElementType,
    AreArrayValidationFieldsRequired
  >;
  arrayErrors?: DefaultErrorType;
}

export type ArrayValuesErrors<
  ElementType,
  AreArrayValidationFieldsRequired extends boolean = false
> = Array<ArrayValueError<ElementType, AreArrayValidationFieldsRequired>>;

export type ArrayValueError<
  ElementType,
  AreArrayValidationFieldsRequired extends boolean = false
> =
  | NestedValidationErrors<ElementType, AreArrayValidationFieldsRequired>
  | undefined;

export function isError(errors: unknown): errors is DefaultErrorType {
  return (
    Array.isArray(errors) &&
    (errors as DefaultErrorType)
      .filter((error) => error)
      .some((error) => error.description || error.severity)
  );
}
