/**
 * @class DataTableHead
 */

import * as React from 'react';

import { IDataGridHeaderProps } from '../DataGrid/DataGridHeader';

import DataTableHeadCell from './DataTableHeadCell';

interface IDataTableHeadProps {
  configuration?: IDataGridHeaderProps[];
  onSortBy?: (value: string) => void;
  sortValue?: string;
  _injected?: any;
  testSelector?: string;
}

export default class DataTableHead extends React.PureComponent<
  IDataTableHeadProps
> {
  public static defaultProps = {
    configuration: [],
  };

  public render() {
    const { configuration, _injected } = this.props;
    const configurations = configuration || [];

    return configurations.map((item: IDataGridHeaderProps, index: number) => {
      return (
        <DataTableHeadCell
          key={index}
          text={item.text}
          sortField={item.sortField}
          sortValue={this.props.sortValue}
          onSortBy={this.props.onSortBy}
          iconName={item.iconName}
          component={item.component}
          rowSpan={item.rowSpan}
          colSpan={item.colSpan}
          size={item.size}
          justify={item.justify}
          class={item.class}
          id={item.id}
          _injected={_injected}
          indexColumn={index}
          columnsAmount={configurations.length}
        />
      );
    });
  }
}
