import moment from 'moment';

export class TimeDateUtils {
  static setTime(
    date: Date | string,
    timeString: string,
    timeStringFormat: string
  ): Date {
    const dateMoment = moment(date);
    const timeMoment = moment(timeString, timeStringFormat);

    return dateMoment
      .set({
        hour: timeMoment.hour(),
        minute: timeMoment.minute(),
        second: timeMoment.second(),
      })
      .toDate();
  }
}
