import { createAction as createReduxAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { ActionCreator } from '../declarations/reduxActions';

/// ACTION CREATORS
export const createAction = <TParams = undefined>(
  actionName: string
): ActionCreator<TParams> => createReduxAction<TParams>(actionName) as any;
export const createAsyncAction = <TParams, TResult, TError = Error>(
  actionName: string
) => createRoutine<TParams, TResult, TError>(actionName);

export const GET_ONE = 'getOne';
export const GET_LIST = 'getList';
export const CREATE = 'create';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const ACTION = 'action';

export const getOneAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${GET_ONE}`);
export const getListAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${GET_LIST}`);
export const createInApiAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${CREATE}`);
export const updateAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${UPDATE}`);
export const deleteAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${DELETE}`);
export const inApiAction = <TParams, TResult, TError = Error>(
  resourceName: string
) => createAsyncAction<TParams, TResult, TError>(`${resourceName}/${ACTION}`);
