/**
 * @class DataGridCell
 */

import * as React from 'react';

import { LayoutHelpers } from '../helpers';

import { IDataGridCellProps } from './DataGridCell';

export interface IDataGridCellInternalProps<T> extends IDataGridCellProps<T> {
  columnsAmount?: number;
  disabled?: boolean;
  indexColumn?: number;
  indexRow?: number;
  gridId?: string;
  highlightAdditionalRow?: boolean;
  isSecondary?: boolean;
  newCssRowControl?: boolean;
}

export default class DataGridCellInternal<T = any> extends React.Component<
  IDataGridCellInternalProps<T>
> {
  public static defaultProps: IDataGridCellInternalProps<any> = {
    justify: 'left',
    highlightAdditionalRow: true,
  };

  private readonly id: string;

  constructor(props: IDataGridCellInternalProps<T>) {
    super(props);

    this.id = this.props.id || LayoutHelpers.getRandomString();
  }

  public render() {
    const { data, content } = this.props;

    return (
      <div
        id={this.id}
        className={this.getClassList()}
        style={this.getCellStyle()}
        {...LayoutHelpers.getTestSelector(this.props.testSelector)}
        {...(!this.props.newCssRowControl
          ? { onMouseEnter: this.onMouseEnter, onMouseLeave: this.onMouseLeave }
          : null)}
        onClick={this.onClick}
        onDoubleClick={this.onDoubleClick}
      >
        {content ? content(data) : null}
        {this.props.children}
      </div>
    );
  }

  public componentDidMount() {
    if (!this.props.newCssRowControl) {
      const higlightedCellSelector = `#${this.props.gridId} > .ac-datagrid-cell.ac-datagrid-selected-row.row-${this.props.indexRow}`;
      const isAnyCellInRowHiglighted = !!document.querySelector(
        higlightedCellSelector
      );

      if (isAnyCellInRowHiglighted) {
        this.highlightingRow('add');
      }
    }
  }

  private onMouseEnter = () => this.highlightingRow('add');
  private onMouseLeave = () => this.highlightingRow('remove');

  private onClick = (e: React.MouseEvent<HTMLElement>) => {
    const { indexColumn, indexRow, data, onClick } = this.props;
    if (onClick && indexColumn !== undefined && indexRow !== undefined) {
      e.stopPropagation();
      onClick({ indexColumn, indexRow, data, id: this.id });
    }
  };

  private onDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { indexColumn, indexRow, data, onDoubleClick } = this.props;
    if (onDoubleClick && indexColumn !== undefined && indexRow !== undefined) {
      e.stopPropagation();
      onDoubleClick({ indexColumn, indexRow, data, id: this.id });
    }
  };

  private highlightingRow = (action: string) => {
    const indexRow = this.props.indexRow;
    const highlightAdditionalRow = this.props.highlightAdditionalRow;

    const array = document.querySelectorAll(
      `#${this.props.gridId} > .ac-datagrid-cell.row-${indexRow}`
    );

    const additionalRow = highlightAdditionalRow
      ? document.querySelector(
          `#${this.props.gridId} > .ac-datagrid-additional-row.row-${indexRow}`
        )
      : undefined;

    if (action === 'add') {
      for (let i = 0; i < array.length!; i++) {
        array[i].classList.add('ac-datagrid-selected-row');
      }
      if (additionalRow) {
        additionalRow.classList.add('ac-datagrid-additional-selected-row');
      }
    } else if (action === 'remove') {
      for (let i = 0; i < array.length!; i++) {
        array[i].classList.remove('ac-datagrid-selected-row');
      }
      if (additionalRow) {
        additionalRow.classList.remove('ac-datagrid-additional-selected-row');
      }
    }
  };

  private getClassList(): string {
    const classList: string[] = [];

    classList.push('ac-datagrid-cell rc-datagrid-cell');

    if (this.props.isSecondary) {
      classList.push('ac-datagrid-secondary-cell');
    }

    if (this.props.indexColumn === 0) {
      classList.push('ac-datagrid-first-cell');
    }

    if (this.props.indexColumn === this.props.columnsAmount! - 1) {
      classList.push('ac-datagrid-last-cell');
    }

    if (!this.props.newCssRowControl) {
      if (LayoutHelpers.isOdd(this.props.indexRow!)) {
        classList.push('ac-datagrid-even-row');
      } else {
        classList.push('ac-datagrid-odd-row');
      }
    }

    classList.push(`ac-datagrid-justify-${this.props.justify || 'left'}`);

    if (this.props.class) {
      classList.push(this.props.class);
    }

    if (this.props.disabled) {
      classList.push('ac-datagrid-cell-disabled');
    }

    return classList.join(' ');
  }

  private getCellStyle = () => {
    const { span } = this.props;
    if (span && span > 1) {
      return {
        gridColumn: `span ${span}`,
      };
    } else {
      return {};
    }
  };
}
