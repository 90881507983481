import { StringKeys } from '@ac/library-utils/dist/declarations';

import { KeyTypeInObjectOrArray, ValueInObjectOrArray } from '../types';

import { NestedRadioButtonField } from './nestedRadioButtonField';
import { RadioButtonField } from './radioButtonField';
import { NestedRadioButtonFieldProps, RadioButtonFieldProps } from './types';

export const formNestedRadioButtonFieldFactory = <ValuesType>(): new <
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<ValuesType, ValueKey1>,
              ValueKey2
            >,
            ValueKey3
          >
        >
      > = never,
  ValueKey5 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<
                ValueInObjectOrArray<ValuesType, ValueKey1>,
                ValueKey2
              >,
              ValueKey3
            >,
            ValueKey4
          >
        >
      > = never
>(
  props: NestedRadioButtonFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
) => NestedRadioButtonField<
  ValuesType,
  ValueKey1,
  ValueKey2,
  ValueKey3,
  ValueKey4,
  ValueKey5
> => NestedRadioButtonField;

export const formRadioButtonFieldFactory = <ValuesType>(): new <
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>
>(
  props: RadioButtonFieldProps<ValuesType, ValueKey>
) => RadioButtonField<ValuesType, ValueKey> => RadioButtonField;
