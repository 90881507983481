import * as Cookies from './cookies';
import * as LocalStorage from './storages/localStorage';
import * as Login from './login/login';
import * as SessionStorage from './storages/sessionStorage';
import * as SessionDataApi from './session/api';
import * as SessionDataHostApi from './session/host';

export {
  SessionDataChangedEvent,
  SessionDataChangedEventPayload,
  SessionDataChangedEventType,
} from './session/event';
export { SessionDataKeysMap } from './session/keys';
export { CookieEvent } from './cookieEvent';
export { CookieEventType } from './cookieEventType';
export { CookieChangedEvent } from './cookieChangedEvent';
export { CookieDeletedEvent } from './cookieDeletedEvent';
export { Storage } from './storages';

export const CookiesService = Cookies;
export const LoginService = Login;
export const LocalStorageFactory = LocalStorage;
export const SessionStorageFactory = SessionStorage;
export const SessionService = SessionDataApi;
export const SessionDataHost = SessionDataHostApi;
