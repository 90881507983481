import { PagingInfo } from '@ac/library-api';

import { DEFAULT_DASHBOARD_GRID_PAGE_SIZE } from '../../config';

import { StandardGridRowItem } from './rowItem';

export * from './rowItem';
export * from './rowItemMetadata';

export enum DashboardGridFetchingStatus {
  InProgress,
  Error,
  Success,
}

export interface StandardDashboardGridWithPaginationState<TGridRowModel> {
  items: Array<StandardGridRowItem<TGridRowModel>>;
  paging: PagingInfo;
  fetching:
    | {
        status: DashboardGridFetchingStatus.InProgress;
      }
    | {
        status: DashboardGridFetchingStatus.Success;
      }
    | {
        status: DashboardGridFetchingStatus.Error;
        error: Error;
      };
}

export const getDefaultStandardDashboardGridWithPaginationState = <
  TGridRowModel
>(): StandardDashboardGridWithPaginationState<TGridRowModel> => ({
  items: [],
  paging: {
    pageNumber: 1,
    pageSize: DEFAULT_DASHBOARD_GRID_PAGE_SIZE,
    totalCount: 0,
  },
  fetching: {
    status: DashboardGridFetchingStatus.Success,
  },
});
