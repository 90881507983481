import moment from 'moment';
import {
  DateComparePrecision,
  DateGranularity,
  DateInclusivity,
  LogicalConditions,
} from '../comparisonOptions';
import { DeprecatedDateType } from './deprecatedDateType';
import { DateUnit } from '../types';
import { IDateRange } from '../../dateRange';

/**
 * @deprecated Use `ComparisonDateUtils` instead (moment.js is not supported anymore)
 */
export class ComparisonDateUtilsDeprecated {
  public static compareDates(
    date1: DeprecatedDateType,
    condition: LogicalConditions,
    date2: DeprecatedDateType,
    comparePrecision?: DateComparePrecision,
    dateTimeFormat?: string
  ): boolean {
    const formattedDate1 = moment(date1, dateTimeFormat);
    const formattedDate2 = moment(date2, dateTimeFormat);

    const conditions = {
      [LogicalConditions.Equal]: formattedDate1.isSame(
        formattedDate2,
        comparePrecision
      ),
      [LogicalConditions.NotEqual]: !formattedDate1.isSame(
        formattedDate2,
        comparePrecision
      ),
      [LogicalConditions.Greater]: formattedDate1.isAfter(
        formattedDate2,
        comparePrecision
      ),
      [LogicalConditions.GreaterOrEqual]: formattedDate1.isSameOrAfter(
        formattedDate2,
        comparePrecision
      ),
      [LogicalConditions.Lower]: formattedDate1.isBefore(
        formattedDate2,
        comparePrecision
      ),
      [LogicalConditions.LowerOrEqual]: formattedDate1.isSameOrBefore(
        formattedDate2,
        comparePrecision
      ),
    };

    return conditions[condition];
  }

  public static diff(
    value1: DeprecatedDateType,
    value2: DeprecatedDateType,
    unit: DateUnit = DateUnit.Seconds
  ): number {
    return moment(value1).diff(value2, unit);
  }

  public static isBetween(
    value: DeprecatedDateType,
    from: DeprecatedDateType,
    to: DeprecatedDateType,
    granularity: DateGranularity = 'days',
    inclusivity: DateInclusivity = '[]'
  ) {
    return moment(value).isBetween(from, to, granularity, inclusivity);
  }

  public static isBefore(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ): boolean {
    return moment(date1).isBefore(date2);
  }

  public static isSameOrBefore(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ): boolean {
    return moment(date1).isSameOrBefore(date2);
  }

  public static isAfter(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ): boolean {
    return moment(date1).isAfter(date2);
  }

  public static isSameOrAfter(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ): boolean {
    return moment(date1).isSameOrAfter(date2);
  }

  public static isColliding(
    range1: IDateRange,
    range2: IDateRange,
    options?: { exact: boolean }
  ): boolean {
    const exact = options?.exact ?? true;
    const isBefore = exact
      ? ComparisonDateUtilsDeprecated.isSameOrBefore
      : ComparisonDateUtilsDeprecated.isBefore;

    const isAfter = exact
      ? ComparisonDateUtilsDeprecated.isSameOrAfter
      : ComparisonDateUtilsDeprecated.isAfter;

    return (
      isAfter(range2.end, range1.start) && isBefore(range2.start, range1.end)
    );
  }

  public static isOutOfRange(
    range1: IDateRange,
    range2: IDateRange,
    options?: { exact: boolean }
  ): boolean {
    const exact = options?.exact ?? false;
    const isBefore = exact
      ? ComparisonDateUtilsDeprecated.isSameOrBefore
      : ComparisonDateUtilsDeprecated.isBefore;

    const isAfter = exact
      ? ComparisonDateUtilsDeprecated.isSameOrAfter
      : ComparisonDateUtilsDeprecated.isAfter;

    const isOutOfStartRange =
      isBefore(range1.start, range2.start) &&
      isBefore(range1.end, range2.start);
    const isOutOfEndRange =
      isAfter(range1.start, range2.end) && isAfter(range1.end, range2.end);

    return isOutOfStartRange || isOutOfEndRange;
  }

  public static isSameDay(
    date1: DeprecatedDateType,
    date2: DeprecatedDateType
  ): boolean {
    return ComparisonDateUtilsDeprecated.compareDates(
      date1,
      LogicalConditions.Equal,
      date2,
      DateComparePrecision.Day
    );
  }
}
