import {
  API_DATE_FORMAT,
  API_DATE_TIME_FORMAT,
  API_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  DISPLAY_TIME_FORMAT_12_HOURS,
} from '../formats';

import { DeprecatedDateType } from './deprecatedDateType';
import { Moment } from 'moment';
import moment from 'moment';

/**
 * @deprecated Use `SerializationDateUtils` instead (moment.js is not supported anymore)
 */
export class SerializationDateUtilsDeprecated {
  public static getMomentFromServerString(value: string): Moment {
    return moment(value, API_DATE_FORMAT);
  }

  public static getMomentWithTimeFromServerString(value: string): Moment {
    return moment(value, API_DATE_TIME_FORMAT);
  }

  public static getDateFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentFromServerString(
      value
    ).toDate();
  }

  public static getDateTimeFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentWithTimeFromServerString(
      value
    ).toDate();
  }

  public static formatDateForApi(date: DeprecatedDateType | undefined): string {
    return date ? moment(date).format(API_DATE_FORMAT) : '';
  }

  public static formatTimeForApi(date: DeprecatedDateType | undefined): string {
    return date ? moment(date).format(API_TIME_FORMAT) : '';
  }

  public static formatDateTimeForApi(
    date: DeprecatedDateType | undefined
  ): string {
    return date ? moment(date).format(API_DATE_TIME_FORMAT) : '';
  }

  public static formatDateTimeForDisplay(
    dateTime?: DeprecatedDateType,
    inputFormat?: string,
    outputFormat?: string
  ): string {
    const resolvedInputFormat = inputFormat || [
      API_DATE_FORMAT,
      API_TIME_FORMAT,
      API_DATE_TIME_FORMAT,
    ];

    const resolvedOutputFormat =
      outputFormat || `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;

    return moment(dateTime, resolvedInputFormat).format(resolvedOutputFormat);
  }

  public static formatTimeTo24(timeString: string): string {
    return moment(timeString, [DISPLAY_TIME_FORMAT_12_HOURS]).format(
      DISPLAY_TIME_FORMAT
    );
  }

  public static formatTimeTo12(timeString: string): string {
    return moment(timeString, [DISPLAY_TIME_FORMAT]).format(
      DISPLAY_TIME_FORMAT_12_HOURS
    );
  }

  public static format(
    date: DeprecatedDateType | undefined,
    format: string
  ): string {
    return date ? moment(date).format(format) : '';
  }
}
