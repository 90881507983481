/**
 * @class DataGridHeading
 */

import * as React from 'react';

import { PropsWithChildren } from '../../../features/reactTransition';

import DataGridHeader, { IDataGridHeaderProps } from './DataGridHeader';

interface IDataGridHeadingProps {
  configuration?: IDataGridHeaderProps[];
  onSortBy?: (value: string) => void;
  sortValue?: string;
  additionalCell?: React.ReactNode;
  testSelector?: string;
}

export default class DataGridHeading extends React.Component<
  PropsWithChildren<IDataGridHeadingProps>
> {
  public static defaultProps: IDataGridHeadingProps = {
    configuration: [],
  };

  public render() {
    const { configuration, additionalCell } = this.props;
    let configurations = configuration || [];
    if (additionalCell) {
      configurations = [
        {
          component: additionalCell,
        } as any,
        ...configurations,
      ];
    }

    return configurations.map((item: IDataGridHeaderProps, index: number) => {
      return (
        <DataGridHeader
          key={index}
          text={item.text}
          sortField={item.sortField}
          sortValue={this.props.sortValue}
          onSortBy={this.props.onSortBy}
          iconName={item.iconName}
          component={item.component}
          rowSpan={item.rowSpan}
          colSpan={item.colSpan}
          size={item.size}
          justify={item.justify}
          class={item.class}
          id={item.id}
          indexColumn={index}
          columnsAmount={configurations.length}
          testSelector={item.testSelector}
        />
      );
    });
  }
}
