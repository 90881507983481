import get from 'lodash/get';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';

import { ValidationResult } from './errors';
import {
  ValidationExpression,
  ValidationSchema,
  ValidationStatuses,
} from './types';

/**@deprecated please use FormValidator<TFormData>*/
export class Validator<P extends object, T extends ValidationStatuses> {
  public schema: ValidationSchema<T> | ValidationExpression<P>;
  public isValid = true;
  public statusesTree: T = {} as T;
  public data!: P;

  constructor(schema: ValidationSchema<T>) {
    this.schema = schema;
  }

  public validateField(data: P, path: string): ValidationResult {
    this.data = data;
    let innerData = data;
    let innerSchema = this.schema;

    for (const pathPart of path.split('.')) {
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      innerSchema = innerSchema[pathPart]!;
      innerData = data ? data[pathPart] : undefined;
      if (!innerSchema) {
        return [];
      }

      if (innerSchema instanceof Function) {
        return innerSchema(innerData, data);
      }
    }

    return [];
  }

  public validate(data: P, path?: string | string[]): T {
    this.data = data;
    const schema = path ? pick(this.schema, path) : this.schema;

    this.isValid = !this.runValidation(data, schema, this.statusesTree);

    this.statusesTree = { ...{}, ...this.statusesTree };

    return this.statusesTree;
  }

  private runValidation(
    data: object,
    schema: ValidationSchema<T> | ValidationExpression<P>,
    statusesTree: ValidationResult | ValidationStatuses
  ): boolean {
    let invalid = false;

    for (const [key, value] of toPairs(schema)) {
      if (value instanceof Function) {
        const dataValue = data && data[key];
        const status = value(dataValue, this.data);
        invalid = !!(status && status.length) || invalid;
        statusesTree[key] = status;
      } else if (Array.isArray(value)) {
        // ToDo: implement for dynamic forms
      } else if (value instanceof Object) {
        statusesTree[key] = {};
        const statusesSlice = statusesTree[key];
        const dataSlice = get(data, key);
        invalid =
          this.runValidation(dataSlice, value, statusesSlice) || invalid;
      }
    }

    return invalid;
  }
}
