import { isDefined } from '@ac/library-utils/dist/utils';

import { ValidationResult, ValidationSeverity } from './errors';
import { ValidationExpression } from './types';

/* eslint-disable no-useless-escape */
const regularExpressions = {
  email:
    /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
  phone:
    /^([\+]?1\s*[-\/\.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-\/\.]?\s*(\d{3})\s*[-\/\.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[\.]?|extension)\s*([#*\d]+))*$/,
  url: /(?:([A-Za-z]+):)?(\/{0,3})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-{}]*[\w@?^=%&amp;\/~+#-{}])??/,
};
/* eslint-enable no-useless-escape */

function isEmpty(val: string): boolean {
  return String(val).replace(/\s/g, '').length === 0;
}

export const createRequiredValidator =
  (description: string) =>
  (value?: string | number | boolean): ValidationResult => {
    const error: ValidationResult = [
      { severity: ValidationSeverity.error, description },
    ];

    if (value === undefined || value === null) {
      return error;
    }

    if (typeof value === 'string' && (!isDefined(value) || isEmpty(value))) {
      return error;
    }

    return [];
  };

export const createEmailValidator = (description: string) =>
  createExpressionValidator(description, regularExpressions.email);

export const createExpressionValidator =
  (description: string, validationExpression: string | RegExp) =>
  (value?: string): ValidationResult => {
    if (!isDefined(value) || isEmpty(value)) {
      return [];
    }

    const regex = new RegExp(validationExpression);

    if (!regex.test(value)) {
      return [{ severity: ValidationSeverity.error, description }];
    }

    return [];
  };

export const maxNumberValidator =
  (max: number, description: string) =>
  (value: number | undefined): ValidationResult =>
    value !== undefined && value <= max
      ? []
      : [{ severity: ValidationSeverity.error, description }];

/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
export const composeValidators =
  <T extends unknown>(...validators: Array<ValidationExpression<T>>) =>
  (value: unknown, model: T): ValidationResult => {
    return validators.reduce(
      (statuses, validator) => statuses.concat(validator(value, model)),
      [] as ValidationResult
    );
  };
