import { StringKeys } from '@ac/library-utils/dist/declarations';

import {
  FieldProps,
  NestedFieldProps,
} from '../internalLibraryTypes/reactForm';

import { FormField } from './field';
import { FormNestedField } from './nestedField';
import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from './types';

export const formFieldFactory = <ValuesType>(): new <
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>,
  FormattedValueType = FieldValueType<ValuesType, ValueKey>
>(
  props: FieldProps<ValuesType, ValueKey, FormattedValueType>
) => FormField<ValuesType, ValueKey, FormattedValueType> => FormField;

export const formNestedFieldFactory = <ValuesType>(): new <
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  // KeyTypeInObjectOrArray is not used because it caused error: `Type instantiation is excessively deep and possibly infinite.`
  // Expanding type alias helped (3.4.5)
  // https://github.com/Microsoft/TypeScript/issues/30188
  // https://kgtkr.net/blog/2019/04/15/typescript-typelevelprogramming-error-suppression/en
  ValueKey4 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<ValuesType, ValueKey1>,
              ValueKey2
            >,
            ValueKey3
          >
        >
      > = never,
  ValueKey5 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<
                ValueInObjectOrArray<ValuesType, ValueKey1>,
                ValueKey2
              >,
              ValueKey3
            >,
            ValueKey4
          >
        >
      > = never,
  FormattedValueType = FieldValueType<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
>(
  props: NestedFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5,
    FormattedValueType
  >
) => FormNestedField<
  ValuesType,
  ValueKey1,
  ValueKey2,
  ValueKey3,
  ValueKey4,
  ValueKey5,
  FormattedValueType
> => FormNestedField;
