import { StringKeys } from '@ac/library-utils/dist/declarations';

import {
  FieldValueType,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '../../types';

import { NestedSingleCheckboxField } from './nestedSingleCheckboxField';
import { SingleCheckboxField } from './singleCheckboxField';
import {
  NestedSingleCheckboxFieldProps,
  SingleCheckboxFieldProps,
} from './types';

export const formSingleCheckboxFieldFactory = <ValuesType>(): new <
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>,
  FormattedValueType = FieldValueType<ValuesType, ValueKey>
>(
  props: SingleCheckboxFieldProps<ValuesType, ValueKey, FormattedValueType>
) => SingleCheckboxField<ValuesType, ValueKey, FormattedValueType> =>
  SingleCheckboxField;

export const formNestedSingleCheckboxFieldFactory = <ValuesType>(): new <
  ValueKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ValueKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ValueKey1>
  > = never,
  ValueKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ValueKey1>, ValueKey2>
  > = never,
  ValueKey4 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ValueKey1>,
        ValueKey2
      >,
      ValueKey3
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<ValuesType, ValueKey1>,
              ValueKey2
            >,
            ValueKey3
          >
        >
      > = never,
  ValueKey5 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<
          ValueInObjectOrArray<ValuesType, ValueKey1>,
          ValueKey2
        >,
        ValueKey3
      >,
      ValueKey4
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<
                ValueInObjectOrArray<ValuesType, ValueKey1>,
                ValueKey2
              >,
              ValueKey3
            >,
            ValueKey4
          >
        >
      > = never
>(
  props: NestedSingleCheckboxFieldProps<
    ValuesType,
    ValueKey1,
    ValueKey2,
    ValueKey3,
    ValueKey4,
    ValueKey5
  >
) => NestedSingleCheckboxField<
  ValuesType,
  ValueKey1,
  ValueKey2,
  ValueKey3,
  ValueKey4,
  ValueKey5
> => NestedSingleCheckboxField;
