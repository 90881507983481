import moment from 'moment';
import { SupportedDateType } from './supportedDateType';

export type UnitOfTime =
  | 'second'
  | 'seconds'
  | 'minute'
  | 'minutes'
  | 'hour'
  | 'hours'
  | 'day'
  | 'days'
  | 'week'
  | 'weeks'
  | 'month'
  | 'months'
  | 'year'
  | 'years';

export class TransformationsDateUtils {
  public static subtractDaysFromDate(
    date: SupportedDateType,
    days: number | string
  ): Date {
    return moment(date).subtract(+days, 'days').toDate();
  }

  public static addDaysToDate(
    startDate: SupportedDateType,
    days: number | string
  ): Date {
    return moment(startDate).add(+days, 'days').toDate();
  }

  static addTimeUnitToDate(
    date: SupportedDateType,
    dateUnitToAdd: number,
    dateUnit: UnitOfTime
  ): Date {
    return moment(date).add(dateUnitToAdd, dateUnit).toDate();
  }

  static subtractTimeUnitToDate(
    date: string | Date,
    dateUnitToSubtract: number,
    dateUnit: UnitOfTime
  ): Date {
    return moment(date).subtract(dateUnitToSubtract, dateUnit).toDate();
  }
}
