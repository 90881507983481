import { StringKeys } from '@ac/library-utils/dist/declarations';

import {
  FieldArrayProps,
  NestedFieldArrayProps,
} from '../../internalLibraryTypes/reactFormArrays';
import { KeyTypeInObjectOrArray, ValueInObjectOrArray } from '../types';

import { FormFieldArray } from './fieldArray';
import { FormNestedFieldArray } from './nestedFieldArray';

export const formFieldArrayFactory = <ValuesType>(): new <
  ValueKey extends KeyTypeInObjectOrArray<ValuesType>
>(
  props: FieldArrayProps<ValuesType, ValueKey>
) => FormFieldArray<ValuesType, ValueKey> => FormFieldArray;

export const formNestedFieldArrayFactory = <ValuesType>(): new <
  ArrayKey1 extends KeyTypeInObjectOrArray<ValuesType>,
  ArrayKey2 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValuesType, ArrayKey1>
  > = never,
  ArrayKey3 extends KeyTypeInObjectOrArray<
    ValueInObjectOrArray<ValueInObjectOrArray<ValuesType, ArrayKey1>, ArrayKey2>
  > = never,
  ArrayKey4 extends NonNullable<
    ValueInObjectOrArray<
      ValueInObjectOrArray<
        ValueInObjectOrArray<ValuesType, ArrayKey1>,
        ArrayKey2
      >,
      ArrayKey3
    >
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  > extends any[]
    ? number
    : StringKeys<
        NonNullable<
          ValueInObjectOrArray<
            ValueInObjectOrArray<
              ValueInObjectOrArray<ValuesType, ArrayKey1>,
              ArrayKey2
            >,
            ArrayKey3
          >
        >
      > = never
>(
  props: NestedFieldArrayProps<
    ValuesType,
    ArrayKey1,
    ArrayKey2,
    ArrayKey3,
    ArrayKey4
  >
) => FormNestedFieldArray<
  ValuesType,
  ArrayKey1,
  ArrayKey2,
  ArrayKey3,
  ArrayKey4
> => FormNestedFieldArray;
