import { SessionDataKeysMap } from './keys';

type SessionDataSnapshot = {
  [key in (typeof SessionDataKeysMap)[keyof typeof SessionDataKeysMap]]:
    | string
    | undefined;
};

export const sessionDataSnapshotKey = 'aboveCloud.sessionData';

export const clearSessionData = (): void => {
  try {
    window.localStorage.removeItem(sessionDataSnapshotKey);

    forEachSessionStorageKey((key) => {
      window.sessionStorage.removeItem(key);
    });
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('clearSessionData failed', error);
  }
};

export const updateSessionDataSnapshot = (): void => {
  try {
    const snapshot = createSessionDataSnapshot();
    const stringifiedEncodedSessionData = JSON.stringify(snapshot);

    window.localStorage.setItem(
      sessionDataSnapshotKey,
      stringifiedEncodedSessionData
    );
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('updateSessionDataSnapshot failed', error);
  }
};

export const readSessionDataSnapshot = (): SessionDataSnapshot | undefined => {
  try {
    const stringifiedSnapshot = localStorage.getItem(sessionDataSnapshotKey);
    if (stringifiedSnapshot) {
      return JSON.parse(stringifiedSnapshot) as SessionDataSnapshot;
    }

    return undefined;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('readSessionDataSnapshot failed', error);

    return undefined;
  }
};

export const hasSessionDataAlready = (): boolean => {
  try {
    const sessionDataKeys = Object.values(SessionDataKeysMap);

    return Object.keys(window.sessionStorage).some((storageKey) => {
      return sessionDataKeys.some((key) => storageKey.startsWith(key));
    });
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('hasSessionDataAlready failed', error);

    return false;
  }
};

export const applySessionDataSnapshot = (): void => {
  const sessionDataSnapshot = readSessionDataSnapshot();
  if (!sessionDataSnapshot) {
    return;
  }

  try {
    Object.entries(sessionDataSnapshot).forEach(([key, value]) => {
      if (value !== undefined) {
        window.sessionStorage.setItem(key, value);
      } else {
        window.sessionStorage.removeItem(key);
      }
    });
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('applySessionDataSnapshot failed', error);

    return undefined;
  }
};

export const createSessionDataSnapshot = (): SessionDataSnapshot => {
  const snapshot = {} as SessionDataSnapshot;

  try {
    forEachSessionStorageKey((key) => {
      snapshot[key] = window.sessionStorage.getItem(key) || undefined;
    });

    return snapshot;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.warn('createSessionDataSnapshot failed', error);

    return snapshot;
  }
};

const forEachSessionStorageKey = (callback: (key: string) => void): void => {
  const sessionDataKeys = Object.values(SessionDataKeysMap);

  Object.keys(window.sessionStorage).forEach((storageKey) => {
    const isSessionDataKey = sessionDataKeys.some((key) =>
      storageKey.startsWith(key)
    );
    if (isSessionDataKey) {
      callback(storageKey);
    }
  });
};
