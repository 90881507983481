import { deepEquals, isObject } from '@ac/library-utils/dist/utils';

export function getChanges<ObjectType>(
  original: ObjectType,
  current: ObjectType
): Partial<ObjectType> {
  return Object.keys(original)
    .filter((key) => {
      if (Array.isArray(original[key]) || Array.isArray(current[key])) {
        return !deepEquals(original[key], current[key]);
      }

      if (isObject(original[key]) || isObject(current[key])) {
        return true;
      }

      return original[key] !== current[key];
    })
    .reduce((result, key) => {
      // in case of changed array (added, removed, changed element), we return full current array.
      // Depending on BE, we should decide what in the case of nested objects - should we return changes on every lvl
      // or maybe nested objects should be untouched (equal to "current" value) and only container should be Patched (Partial).
      // But still - this decision can be made in upper layer.
      // This results in small incosistency between array and object.
      // So alternatively - we can change array type to object which will contain 3 arrays: removed, added, changed elements.
      if (isObject(original[key]) || isObject(current[key])) {
        result[key] = getChanges(original[key] || {}, current[key] || {});
      } else {
        result[key] = current[key];
      }

      return result;
    }, {} as Partial<ObjectType>);
}
