export const trim = (
  strings: TemplateStringsArray,
  ...values: unknown[]
): string => {
  const trimmedStrings = strings.map((str, i) => `${str}${values[i] ?? ''}`);
  return trimmedStrings
    .join('')
    .replace(/\s{2,}/g, ' ')
    .trim();
};
