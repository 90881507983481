/**
 * Use this file functions only in standalone apps and FeContainer.
 **/
import { SessionDataChangedEventType } from './event';
import {
  applySessionDataSnapshot,
  hasSessionDataAlready,
  updateSessionDataSnapshot,
} from './snapshot';

export { clearSessionData, updateSessionDataSnapshot } from './snapshot';

export const setupSessionData = (
  callbackWithSessionModification?: () => void
) => {
  if (!hasSessionDataAlready()) {
    applySessionDataSnapshot();
  }
  if (callbackWithSessionModification) {
    callbackWithSessionModification();
    updateSessionDataSnapshot();
  }
};

type StopMethod = () => void;

export const startSessionDataUpdater = (): StopMethod => {
  const updateSnapshot = (): void => {
    if (!document.hidden) {
      updateSessionDataSnapshot();
    }
  };

  document.addEventListener('visibilitychange', updateSnapshot);
  window.addEventListener(SessionDataChangedEventType, updateSnapshot);

  return () => {
    document.removeEventListener('visibilitychange', updateSnapshot);
    window.removeEventListener(SessionDataChangedEventType, updateSnapshot);
  };
};
