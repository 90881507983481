let id = 0;

export function guid(prefix?: string): string {
  let guidPrefix = 'guid-';

  if (prefix) {
    guidPrefix = `${prefix}-${guidPrefix}`;
  }

  return `${guidPrefix}${id++}`;
}
