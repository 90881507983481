import { ResourceKey } from 'i18next';
import { HttpBackendOptions, RequestCallback } from 'i18next-http-backend';
import { getI18nManifest } from '../manifest';
import { getNamespaceFromUrl } from '../getNamespaceFromUrl';

const getHash = (namespace: string): string => {
  return getI18nManifest().hashMap[namespace] ?? '';
};

type RequestCallbackHandler = (
  error: unknown,
  response?: { status: number; data?: ResourceKey }
) => void;

/**
 * Custom translation requester to be able force cache for en.json
 * It will be the only translation file deployed together with the app
 * Thanks to this we are able to use v query param for cache invalidation
 **/
export const translationRequester = (
  options: HttpBackendOptions,
  url: string,
  payload: {} | string,
  callback: RequestCallback
): void => {
  const requestCallback = callback as RequestCallbackHandler;
  const isProductionProcess = process.env['NODE_ENV'] === 'production';
  const isEnTranslationRequest = url?.endsWith('en.json');
  const isRequestWithHash = isEnTranslationRequest && isProductionProcess;

  const searchParams = new URLSearchParams({
    ...options.queryStringParams,
    ...(isRequestWithHash
      ? { v: getHash(getNamespaceFromUrl(url)) }
      : undefined),
  }).toString();

  const headers =
    typeof options.customHeaders === 'function'
      ? options.customHeaders()
      : options.customHeaders;

  const reqOptions =
    typeof options.requestOptions === 'function'
      ? options.requestOptions(payload)
      : options.requestOptions;

  fetch(
    `${url}${searchParams ? `?${searchParams}` : ''}`,
    Object.assign({}, reqOptions, {
      headers,
    })
  )
    .then((response) => {
      if (!response.ok) {
        return requestCallback(response.statusText || 'Error', {
          status: response.status,
        });
      }

      response
        .text()
        .then((data) => {
          requestCallback(null, { status: response.status, data });
        })
        .catch((error) => requestCallback(error, { status: response.status }));
    })
    .catch((error) => requestCallback(error));
};
