import DOMPurify from 'dompurify';

export const DOMPURIFY_CONFIG = {
  ALLOWED_TAGS: [
    'div',
    'span',
    'b',
    'br',
    'u',
    'i',
    'ol',
    'ul',
    'li',
    'a',
    'p',
    'em',
    'strong',
  ],
  ALLOWED_ATTR: ['target', 'href'],
};

const PATTERNS = [
  {
    pattern: /&lt;/g,
    replacement: '<',
  },
  {
    pattern: /&gt;/g,
    replacement: '>',
  },
];

const getStringForDompurify = (stringContent: string): string =>
  PATTERNS.reduce(
    (value, f) => value.replace(f.pattern, f.replacement),
    stringContent
  );

export const getSafeExternalContent = (
  stringContent?: string,
  customConfig?: object
) =>
  !!stringContent
    ? DOMPurify.sanitize(
        getStringForDompurify(stringContent),
        customConfig || DOMPURIFY_CONFIG
      )
    : stringContent;
