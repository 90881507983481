import { isDefined } from '../../utils';

const generateRandomString = (length: number) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const createCodeVerifier = (length: number) =>
  generateRandomString(length);

const replaceSpecialChars = (input: string): string =>
  input.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

export const createCodeChallenge = async (input: string): Promise<string> => {
  if (isDefined(crypto.subtle)) {
    const utf8 = new TextEncoder().encode(input);
    const digest = await crypto.subtle.digest('SHA-256', utf8);

    return replaceSpecialChars(
      btoa(String.fromCharCode(...new Uint8Array(digest)))
    );
  }
  // crypto.subtle is available only in secure contexts (HTTPS)
  // for none secure contexts like localhost or IP address lazy load 3rd-party library
  const { default: Sha256 } = await import('jssha/dist/sha256');
  const hash = new Sha256('SHA-256', 'TEXT', { encoding: 'UTF8' });
  hash.update(input);

  return replaceSpecialChars(hash.getHash('B64'));
};
