import { b64DecodeUnicode, b64EncodeUnicode } from '../base64';
import { scheduleSessionDataChangedEvent } from './scheduleEvent';
import { SessionDataKeysMap } from './keys';

export const getCentralReservationOfficeId = (): string | undefined =>
  getItem(SessionDataKeysMap.centralReservationOfficeId);

export const setCentralReservationOfficeId = (value?: string): void =>
  setItem(SessionDataKeysMap.centralReservationOfficeId, value);

export const getLanguage = (): string | undefined =>
  getItem(SessionDataKeysMap.language);

export const setLanguage = (value?: string): void =>
  setItem(SessionDataKeysMap.language, value);

export const getPropertyId = (): string | undefined =>
  getItem(SessionDataKeysMap.propertyId);

export const setPropertyId = (value?: string): void =>
  setItem(SessionDataKeysMap.propertyId, value);

export const getSelectedWorkstations = (): string | undefined =>
  getItem(SessionDataKeysMap.selectedWorkstations);

export const setSelectedWorkstations = (value?: string): void => {
  setItem(SessionDataKeysMap.selectedWorkstations, value);
};

export const getReadOnlyMode = (): boolean => {
  const storedValue = getItem(SessionDataKeysMap.readOnlyMode);

  return storedValue ? Boolean(Number(storedValue)) : false;
};

export const setReadOnlyMode = (value: boolean): void => {
  setItem(SessionDataKeysMap.readOnlyMode, String(Number(value)));
};

export const getCurrentDate = (): string | undefined => {
  return getItem(SessionDataKeysMap.currentDate);
};

export const setCurrentDate = (value: string | undefined): void => {
  setItem(SessionDataKeysMap.currentDate, value);
};

export const getLastUsedDeviceId = (): string | undefined => {
  return getItem(SessionDataKeysMap.lastUsedDevice);
};

export const setLastUsedDeviceId = (value: string | undefined): void => {
  setItem(SessionDataKeysMap.lastUsedDevice, value);
};

export const getCashierNumber = (): number | undefined => {
  const storedValue = getItem(SessionDataKeysMap.cashierNumber);

  return storedValue ? Number(storedValue) : undefined;
};

export const setCashierNumber = (value?: number): void =>
  setItem(SessionDataKeysMap.cashierNumber, String(value));

const getPropertyCashierNumberKey = (
  propertyId?: string
): string | undefined => {
  return propertyId
    ? `${SessionDataKeysMap.cashierNumber}.${propertyId}`
    : undefined;
};

export const getPropertyCashierNumber = (
  propertyId?: string
): number | undefined => {
  const key = getPropertyCashierNumberKey(propertyId);
  const storedValue = key ? getItem(key) : undefined;

  return storedValue ? Number(storedValue) : undefined;
};

export const setPropertyCashierNumber = (
  propertyId?: string,
  value?: number
): void => {
  const key = getPropertyCashierNumberKey(propertyId);

  if (key) {
    setItem(key, String(value));
  }
};

const getItem = (key: string): string | undefined => {
  const encodedValue = window.sessionStorage.getItem(key);

  if (encodedValue) {
    return b64DecodeUnicode(encodedValue);
  }

  return undefined;
};

const setItem = (key: string, nextValue: string | undefined): void => {
  const currentValue = getItem(key);
  if (currentValue === nextValue) {
    return;
  }

  if (nextValue === undefined) {
    sessionStorage.removeItem(key);
  } else {
    const encodedValue = b64EncodeUnicode(nextValue);
    sessionStorage.setItem(key, encodedValue);
  }

  scheduleSessionDataChangedEvent(key);
};
