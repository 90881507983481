import {
  FieldState as FinalFieldState,
  FormApi as FinalFormApi,
  FormState as FinalFormState,
  ValidationErrors as FinalValidationErrors,
} from 'final-form';

import {
  RequiredFields,
  StringKeys,
} from '@ac/library-utils/dist/declarations';
import { isObject } from '@ac/library-utils/dist/utils';

import { mapArrayInternalStateToPublic } from './field/array/internalRenderPropsMapper';
import {
  isInternalArrayFieldValidation,
  mapArrayInternalErrorsToPublic,
} from './field/array/internalRenderPropsMapper';
import { mapInternalFieldPlaneStateToPublic } from './field/internalRenderPropsMapper';
import {
  FieldState,
  FormApi,
  FormFieldState,
  FormState,
  FormStateErrors,
  StatusFlags,
} from './internalLibraryTypes/form';
import {
  InternalNestedValidationErrors,
  InternalValidationErrors,
  NestedValidationErrors,
} from './validation/errors';

export const mapInternalFieldStateToPublic = <FieldType>(
  fieldState: FinalFieldState<FieldType>
): FormFieldState<FieldType> => {
  return (
    fieldState.value instanceof Array
      ? mapArrayInternalStateToPublic(fieldState)
      : mapClassicInternalFieldStateToPublic(fieldState)
  ) as FormFieldState<FieldType>;
};

const mapClassicInternalFieldStateToPublic = <FieldType>(
  fieldState: FinalFieldState<FieldType>
): FieldState<FieldType> => ({
  ...(fieldState as RequiredFields<FinalFieldState<FieldType>, 'value'>),
  ...mapInternalFieldPlaneStateToPublic(fieldState),
  focusIn: fieldState.focus,
  focusOut: fieldState.blur,
});

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const changeManyFormValues = <FormData extends Record<string, any>>(
  form: FinalFormApi<FormData>,
  values: Partial<FormData>
) => {
  form.batch(() => {
    Object.entries(values).forEach(([key, value]) => {
      form.change(key, value);
    });
  });
};

export const mapInternalFormStateToPublic = <FormData extends object>(
  internalFormState: FinalFormState<FormData>
): FormState<FormData> => ({
  ...internalFormState,
  values: internalFormState.values as FormData,
  active: internalFormState.active as StringKeys<FormData>,
  error: internalFormState.error || [],
  errors: mapFormInternalErrosToPublic(internalFormState.errors),
  initialValues: internalFormState.initialValues as FormData,
  submitError: internalFormState.submitError || [],
  submitErrors: mapFormInternalErrosToPublic(internalFormState.submitErrors),
  touched: internalFormState.touched as StatusFlags<FormData>,
  modified: internalFormState.modified as StatusFlags<FormData>,
  visited: internalFormState.visited as StatusFlags<FormData>,
  dirtyFields: internalFormState.dirtyFields as StatusFlags<FormData>,
});

export const mapInternalFormApiToPublic = <FormData extends object>(
  form: FinalFormApi<FormData>,
  getFormState: () => FormState<FormData>,
  getFieldState: FormApi<FormData>['getFieldState'],
  changeMany: FormApi<FormData>['changeMany']
): FormApi<FormData> => ({
  ...form,
  focusIn: form.focus,
  focusOut: form.blur,
  getState: getFormState,
  getRegisteredFields: form.getRegisteredFields as () => Array<
    StringKeys<FormData>
  >,
  getFieldState,
  changeMany,
  // TODO: `as any` can be removed, when https://github.com/final-form/final-form/pull/354 will be merged
  // and lib will be updated.
  // Issue: https://github.com/final-form/final-form/issues/352
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  reset: (form as any).restart,
});

const mapFormInternalErrosToPublic = <ValuesType extends object>(
  internalValidationErrors: FinalValidationErrors
): FormStateErrors<ValuesType> => {
  const internalValidation: InternalNestedValidationErrors<ValuesType> =
    internalValidationErrors as InternalValidationErrors<ValuesType>;

  const result: FormStateErrors<ValuesType> =
    mapInternalErrorsToPublic(internalValidation);

  return result;
};

const mapInternalErrorsToPublic = <FormData extends object>(
  errors: InternalNestedValidationErrors<FormData> | undefined
): NestedValidationErrors<FormData, true> =>
  (errors &&
    Object.entries(errors).reduce((result, [key, value]) => {
      if (isInternalArrayFieldValidation(value)) {
        result[key] = mapArrayInternalErrorsToPublic(value);
      } else if (isObject(value)) {
        result[key] = mapInternalErrorsToPublic(value);
      } else {
        result[key] = value;
      }

      return result;
    }, {} as NestedValidationErrors<FormData, true>)) ||
  ({} as NestedValidationErrors<FormData, true>);
