/**
 * @class DataGridSecondaryCell
 */

import * as React from 'react';

import { IDataGridCellProps } from './DataGridCell';

export default class DataGridSecondaryCell<T = any> extends React.Component<
  IDataGridCellProps<T>
> {}
